import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Grid from "@mui/material/Grid";
import { Avatar, Typography } from "@mui/material";
import "../../Styles/Page.css";
import { ProfileInfo } from "../../components/User/ProfileInfo";
import { AvatarInfo } from "../../components/User/AvatarInfo";
import SelectTextFields from "../../components/TextField/Select";
import AlFooter from "../../components/Footer/AlFooter";

export const Legends = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const [data, setData] = useState(null); // Initialize as null for loading state
  const [error, setError] = useState(null); // Track errors
  const baseUrl = `https://back.airsoftlegendsapp.com/API_AL/legends.php`;

  function peticionGet() {
    axios
      .get(baseUrl)
      .then((response) => {
        console.log(response.data);
        console.log(user);
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
      });
  }

  useEffect(() => {
    peticionGet();
  }, []);

  return (
    <div className="profile">
      {/* Header component (if needed) */}
      <header>
        {/* Header content */}
        <Navbar></Navbar>
      </header>

      {/* Main content component (required) */}
      <main>
        {/* Your main content here */}
        <div>isain</div>

        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            alignItems: "center", // Center vertically
            justifyContent: "center",
          }}
        >
          <Grid item xs={4} sm={4} md={4} sx={{}}>
            <SelectTextFields></SelectTextFields>
          </Grid>

          <Grid item xs={4} sm={4} md={4} sx={{}}>
            <SelectTextFields></SelectTextFields>
          </Grid>
          <Grid item xs={4} sm={4} md={4} sx={{}}>
            <SelectTextFields></SelectTextFields>
          </Grid>
        </Grid>
        {error ? (
          <div>Error: {error.message}</div>
        ) : data === null ? (
          <div>Cargando datos...</div>
        ) : data.length > 0 ? (
          data.map((item) => (
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              style={{
                display: "flex", // Add display: flex for horizontal centering
                justifyContent: "center", // Center the content horizontally
                alignItems: "center", // Center the content vertically (optional)
                position: "relative",
              }}
              key={`id-${item.id}`}
            >
              <Grid
                container
                xs={8}
                sm={8}
                md={8}
                style={{
                  backgroundColor: "#191919",
                  height: "100%",
                  overflowX: "auto", // Add overflowY to allow scrolling within the container (optional)
                  position: "relative", // Make the container position relative for fixed positioning (optional)
                  border: "2px solid #3D3E3F",
                  display: "flex", // Add display: flex for horizontal centering
                  justifyContent: "center", // Center the content horizontally
                  alignItems: "center", // Center the content vertically (optional)
                }}
              >
                <Grid
                  item
                  xs={10}
                  sm={4}
                  md={4}
                  height={200}
                  sx={{
                    backgroundColor: "#191919",
                    borderLeft: "1px solid #3e3f40", // Concise and consistent border application
                    borderRadius: 2,
                    marginTop: 5,
                  }}
                >
                  <AvatarInfo userInfo={item}></AvatarInfo>
                </Grid>

                <Grid
                  item
                  xs={10}
                  sm={4}
                  md={4}
                  height={200}
                  sx={{
                    backgroundColor: "#191919",
                    borderRight: "1px solid #3e3f40", // Concise and consistent border application
                    borderRadius: 2,
                    display: "flex",
                    alignItems: "center", // Center vertically
                    justifyContent: "center",
                    marginTop: 5,
                  }}
                >
                  <ProfileInfo userInfo={item}></ProfileInfo>
                </Grid>

                <Grid item xs={12} sm={12} md={12} height={50} sx={{}}></Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} height={10} sx={{}}></Grid>
            </Grid>
          ))
        ) : (
          <tr>
            <td colSpan="11">No se encontraron datos.</td>
          </tr>
        )}

        <div>Teams</div>

        <Grid
          container
          width="100%"
          justifyContent="center"
          sx={
            {
              // Darkgray color code
            }
          }
        >
          <Grid item xs={12} sm={12} md={12} height={50} sx={{}}></Grid>
          {user && ( // Check if user exists before rendering its info
            <></>
          )}
          {!user && ( // Display a fallback message if user is null or undefined
            <p>User information not available.</p>
          )}
        </Grid>
      </main>

      {/* Footer component (if needed) */}
      <footer>
        {/* Footer content */}
        <AlFooter></AlFooter>
      </footer>
    </div>

  );
};

export default Legends;
