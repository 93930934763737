import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Avatar, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import MailIcon from "@mui/icons-material/Mail";
import SendIcon from "@mui/icons-material/Send";
import PeopleIcon from "@mui/icons-material/People";

import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

export default function CampaignForm(){

  const user = JSON.parse(localStorage.getItem("user"));

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <Box
      border={0}
      height={250}
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="transparent"
    >
      <Grid
        container
        spacing={0}
        border={0}
        borderRight={5}
        height="95%"
        width="95%"
        marginTop={0}
        sx={{
          backgroundColor: "#2d2d2d", // Darkgray color code
          borderColor: "#a17d45",
          borderRadius: 5,
        }}
      >
        <Stack
          direction="row"
          useFlexGap
          flexWrap="wrap"
          border={0}
          height={"100%"}
          width={"100%"}
        >
          <Box
            sx={{
              width: "35%", // Adjust width as needed
              height: "70%", // Adjust height as needed
              borderRadius: 1,
              display: "flex", // Enable flexbox for centering
              flexDirection: "column", // Center vertically
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center horizontally
            }}
          >
            <Item sx={{ backgroundColor: "#2d2d2d" }}>
              <Typography
                variant="h4"
                component="h2"
                sx={{
                  color: "white",
                }}
              >
                29
              </Typography>
              <Typography
                variant="subtitle1"
                component="h2"
                sx={{
                  color: "white",
                }}
              >
                September
              </Typography>
              <Typography
                variant="caption"
                component="h2"
                sx={{
                  color: "#7e7e7e",
                }}
              >
                13:30 pm
              </Typography>
            </Item>
          </Box>

          <Box
            sx={{
              width: "65%",
              height: "70%",
              borderRadius: 1,
              display: "flex", // Enable flexbox for centering
              flexDirection: "column", // Center vertically
              justifyContent: "center", // Center horizontally
            }}
          >
            <Button
              variant="outlined"
              size="large"
              endIcon={<SendIcon />}
              sx={{
                width: "90%",
                height: "30%",
                color: "#7e7e7e", // Set text color to #a17d45
                borderColor: "#7e7e7e",
              }}
            >
              Check Details
            </Button>
            <Typography
              variant="subtitle2"
              component="h2"
              align="left"
              sx={{
                color: "white",
              }}
            >
              Campaign Name
            </Typography>
            <Typography
              variant="BUTTON"
              component="h2"
              align="left"
              sx={{
                color: "white",
              }}
            >
              October
            </Typography>
            <Typography
              variant="subtitle2"
              component="h2"
              align="left"
              sx={{
                color: "#7e7e7e",
              }}
            >
              Connections
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              height: "30%",
              borderRadius: 1,
              display: "flex", // Enable flexbox for centering
              flexDirection: "column", // Center vertically
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center horizontally
            }}
          >
            <Stack
              direction="row" //Sets horizontal layout
              useFlexGap
              flexWrap="wrap"
              border={0}
              height={"100%"}
              width={"100%"}
              sx={{
                display: "flex", // Enables flexbox for styling
              }}
            >
              <Avatar
                alt={user.name || "User Name"}
                src={user.photoURL}
                sx={{
                  width: 30,
                  height: 30,
                  marginLeft: 4,
                  marginTop: 1,
                  marginRight: 4,
                }}
              />
              <Typography
                variant="subtitle1"
                component="h2"
                color={{
                  color: "white",
                  marginTop: 4,
                  marginRight: 60,
                }}
              >
                <>Hosted by {user.displayName}</>
              </Typography>

              <PeopleIcon
                color="#a17d45"
                sx={{
                  marginTop: 2,
                  marginLeft: 5,
                  color: "#a17d45",
                }}
              >
                {"10/10"}
              </PeopleIcon>
            </Stack>
          </Box>
        </Stack>
      </Grid>
    </Box>
  );
}
