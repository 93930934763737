// ffc
import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../Styles/main.css";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Navbar() {
  const user = JSON.parse(localStorage.getItem("user"));
  const showAvatar = user && user.photoURL;
  const navref = useRef(null); // Inicializa ref con null
  const navigate = useNavigate();

  const showNavBar = () => {
    if (navref.current) {
      // Verifica si la referencia existe
      navref.current.classList.toggle("responsive_nav");
    } else {
      console.error("Referencia de nav no encontrada");
    }
  };

  const showProfile = () => {
    console.log("click isain");
    navigate("/");
  };

  const showLegends = () => {
    console.log("click legends");
    navigate("/legends");
  };

  return (
    <header>
      <h3>AIRSOFT LEGENDS</h3>
      <nav ref={navref}>
        <Link to="/legends">Legends</Link>
        <Link to="/teams">Teams</Link>
        <Link to="/campaigns">Campaigns</Link>
        <Link to="/arenas">Arenas</Link>
        <Link to="/stores">Stores</Link>
        <Link to="/stars">Stars</Link>
        <Link to="/news">News</Link>

        <button className="nav-btn nav-close-btn" onClick={showNavBar}>
          <FaTimes />
        </button>
      </nav>

      {showAvatar && (
        <Avatar
          alt={user.name || "User Name"}
          src={user.photoURL}
          sx={{ width: 40, height: 40 }}
          onClick={showProfile}
        ></Avatar>
      )}

      <button className="nav-btn" onClick={showNavBar}>
        <FaBars />
      </button>
    </header>
  );
}

export default Navbar;
