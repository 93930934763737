import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import androidImage from "./../../Images/android.png";
import alImage from "./../../Images/al.png";
import cityImage from "./../../Images/barcelona.jpg";
import lastTime from "./../../Images/lastTime.svg";
import rankImage from "./../../Images/rank.svg";
import inALImage from "./../../Images/inAL.svg";


export const GameInfo = (props) => {
  return (
    <Box border={0} height={250}>
      <Grid container>
        <Grid item border={0}>
          <List
            sx={{
              width: "100%",
              maxWidth: 250,
              maxHeight: "70%",
              bgcolor: "#191919",
            }}
          >
            <ListItem alignItems="flex-start" sx={{ paddingBottom: "1px" }}>
              <ListItemAvatar>
                <Avatar alt="Remy Sharp" src={rankImage}/>
              </ListItemAvatar>
              <ListItemText
                primary="Rank"
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="white"
                    >
                      {props.userInfo.rName}
                    </Typography>
                  </React.Fragment>
                }
                sx={{ color: "white" }}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start" sx={{ paddingBottom: "1px" }}>
              <ListItemAvatar>
                <Avatar alt="Travis Howard" src={lastTime}/>
              </ListItemAvatar>
              <ListItemText
                primary="Last time played"
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="white"
                    >
                      {props.userInfo.lastGame}
                    </Typography>
                  </React.Fragment>
                }
                sx={{ color: "white" }}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start" sx={{ paddingBottom: "1px" }}>
              <ListItemAvatar>
                <Avatar alt="Cindy Baker" src={cityImage}/>
              </ListItemAvatar>
              <ListItemText
                primary="Current Location"
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="white"
                    >
                      Barcelona
                    </Typography>
                  </React.Fragment>
                }
                sx={{ color: "white" }}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start" sx={{ paddingBottom: "1px" }}>
              <ListItemAvatar>
                <Avatar alt="Cindy Baker" src={inALImage} />
              </ListItemAvatar>
              <ListItemText
                primary="In AL since"
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="white"
                    >
                      {props.userInfo.created_date}
                    </Typography>
                  </React.Fragment>
                }
                sx={{ color: "white" }}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Cindy Baker" src={alImage} />
              </ListItemAvatar>
              <ListItemText
                primary="Team Owner"
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="white"
                    >
                      {props.userInfo.tName}
                    </Typography>
                  </React.Fragment>
                }
                sx={{ color: "white" }}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};
