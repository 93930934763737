import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createRoutesFromElements,
  RouterProvider,
  Route,
  createBrowserRouter,
} from "react-router-dom"
import Home from './pages/Home';
import Login from './pages/Login';
import Arenas from './pages/Arenas/Arenas';
import CreateArena from './pages/Arenas/CreateArena';
import Protected from './components/Protected';
import Campaigns from './pages/Campaigns/Campaigns';
import Stores from './pages/Stores/Stores';
import Legends from './pages/Legends/Legends';
import Stars from './pages/Stars/Stars';
import Teams from './pages/Teams/Teams';
import News from './pages/News/News';

import { ThemeProvider,createTheme } from '@mui/material/styles';



const router = createBrowserRouter (
  createRoutesFromElements (
    <Route path='/' element={<App />}>
      <Route path='login' element={<Login/>} />
      <Route path='arenas' element={<Arenas/>} />
      <Route path='campaigns' element={<Campaigns/>}/>
      <Route path='stores' element={<Stores/>}/>
      <Route path='legends' element={<Legends/>}/>
      <Route path='stars' element={<Stars/>}></Route>
      <Route path='teams' element={<Teams/>}></Route>
      <Route path='/news' element={<News/>}/>
      <Route path='/' element={<Protected/>}>
        <Route path='/' index element={<Home />} />
      </Route> 
      <Route path='/createArena' element={<CreateArena/>}/>
      
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
  palette: {
    primary: {
      main: '#DC8E1F',
    },
    secondary: {
      light: '#ff7961',
      main: '#DC8E1F',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

root.render(

  <RouterProvider router={router}/>,
  <ThemeProvider theme={theme}>
    <App></App>
  </ThemeProvider>,

);

// Ruta original -- Isain
/*const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
