import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Grid, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';

export const StarsList = (props) => {
    const { starInfo } = props;
    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 25,
        height: 25,
        border: `2px solid grey`,
        backgroundColor: 'black',
        fontSize: '0.65rem',
    }));

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    return (
        <Grid container spacing={2} padding={2} sx={{ width: '80vw', height: '80vh', padding: 0 }}>
            {starInfo.map((arena, index) => (
                <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                    <Card sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '#191919', // Set background color for the Card
                        color: 'white', // Set text color for the Card
                        border: '1px solid #a17d45',
                        borderRadius: 2,
                    }}>
                        <CardActionArea>
                            <CardHeader
                                
                                avatar={
                                    <Box sx={{ padding: 0.5, borderRadius: '50%', border: '3px solid #a17d45',justifyContent:'center' }}>
                                        <Badge
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            badgeContent={
                                                <SmallAvatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" >23</SmallAvatar>
                                            }
                                        >
                                            <Avatar sx={{ bgcolor: red[500], width: 80, height: 80 }} aria-label="recipe" src='https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDR8fGZvcmVzdHxlbnwwfHx8fDE2Mjc3NDU5OTY&ixlib=rb-1.2.1&q=80&w=400'>

                                            </Avatar>
                                        </Badge>
                                    </Box>




                                }

                                title="Isain Rodriguez"
                                subheader="@isa"
                                titleTypographyProps={{ 
                                    style: { fontSize: '1.2rem', fontWeight: 'bold' } // Tamaño y peso del texto del título
                                }}
                                subheaderTypographyProps={{ style: { color: 'gray', fontSize: '1rem', fontWeight: 'bold' } }}>

                            </CardHeader>


                            <Stack
                                direction="row"
                                divider={<Divider orientation="vertical" color='darkgrey' flexItem />}
                                spacing={1}
                                justifyContent="center" alignItems="center"
                                marginTop={1}
                                height={30}

                            >
                                <Item sx={{ backgroundColor: '#191919', color: "white" }}>100
                                    <Typography variant="caption" component="h2" sx={{ color: 'white' }}>
                                        Connections
                                    </Typography>
                                </Item>

                                <Item sx={{ backgroundColor: '#191919', color: "white" }}>50
                                    <Typography variant="caption" component="h2">
                                        Played
                                    </Typography>
                                </Item>

                                <Item sx={{ backgroundColor: '#191919', color: "white" }}>7
                                    <Typography variant="caption" component="h2">
                                        Hosted
                                    </Typography>
                                </Item>

                                <Item sx={{ backgroundColor: '#191919', color: "white" }}>5
                                    <Typography variant="caption" component="h2">
                                        Awards
                                    </Typography>
                                </Item>
                            </Stack>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '10px', marginTop: '10px' }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                        color: '#a17d45',
                                        border: '1px solid #a17d45',
                                        margin: '1px',
                                        width: '80%' // Ajusta el ancho al 80% del contenedor
                                    }}
                                >
                                    Add Legend
                                </Button>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '5px', marginTop: '5px' }}>
                                <List sx={{ width: '80%', bgcolor: 'background.paper', backgroundColor: 'transparent' }}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <ImageIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Current Location"
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        sx={{ display: "inline" }}
                                                        component="span"
                                                        variant="body2"
                                                        color="grey"
                                                    >
                                                        Barcelona
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                            sx={{ color: "white" }} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <WorkIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Current Location"
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        sx={{ display: "inline" }}
                                                        component="span"
                                                        variant="body2"
                                                        color="grey"
                                                    >
                                                        Barcelona
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                            sx={{ color: "white" }} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <BeachAccessIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Current Location"
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        sx={{ display: "inline" }}
                                                        component="span"
                                                        variant="body2"
                                                        color="grey"
                                                    >
                                                        Barcelona
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                            sx={{ color: "white" }} />
                                    </ListItem>
                                </List>

                            </Box>

                        </CardActionArea>

                    </Card>
                </Grid>
            ))
            }
        </Grid >
    );
}
