import React from 'react';
import './../../Styles/Page.css';

const AppBtn = ({ label, image, onClick }) => {
  return (
    <button className="apple-button" onClick={onClick}>
      <img src={image} alt={label} />
      <span>{label}</span>
    </button>
  );
};

export default AppBtn;
