import { signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import axios from "axios";
import Button from "@mui/material/Button";
import { Avatar, Grid } from "@mui/material";
import Navbar from "../components/Navbar";
import "../Styles/Page.css";
import IconButton from "@mui/material/IconButton";
import Fingerprint from "@mui/icons-material/Fingerprint";
import AlFooter from "../components/Footer/AlFooter";
import { AvatarInfo } from "../components/User/AvatarInfo";
import { ProfileInfo } from "../components/User/ProfileInfo";
import { GameInfo } from "../components/User/GameInfo";
import CampaignForm from "../components/Campaigns/CampaignForm";
import { Connections } from "../components/User/Connections";

export const Home = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const [data, setData] = useState([]); // Initialize as an empty array
  const [error, setError] = useState(null); // Track errors
  const baseUrl = `https://back.airsoftlegendsapp.com/API_AL/connect.php?email=${user.email}`;

  const peticionGet = async () => {
    try {
      console.log("User:----->>>>>>11111", user);
      const response = await axios.get(baseUrl);
      console.log("Data fetched:", response.data);
      console.log("User:----->>>>>>", user);
      if (Array.isArray(response.data)) {
        setData(response.data);
      } else {
        console.error("Data is not an array:", response.data);
        setError(new Error("Data format is incorrect."));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error);
    }
  };

  useEffect(() => {
    peticionGet();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  const arenasLink = async () => {
    try {
      navigate("/arenas");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="profile">
      <header>
        <Navbar />
      </header>

      <main>
        {error ? (
          <div>Error: {error.message}</div>
        ) : data.length === 0 ? (
          <div>Cargando datos...</div>
        ) : (
          data.map((item) => (
            <Grid
              container
              width="100%"
              justifyContent="center"
              item
              key={`id-${item.id}`}
            >
              <Grid item xs={12} sm={12} md={12} height={30} sx={{}}></Grid>
              {user && ( // Check if user exists before rendering its info
                <>
                  <Grid
                    item
                    xs={10}
                    sm={4}
                    md={4}
                    height={200}
                    sx={{
                      backgroundColor: "#191919",
                      borderLeft: "1px solid #3e3f40",
                      borderRadius: 2,
                    }}
                  >
                    <AvatarInfo userInfo={item} />
                  </Grid>

                  <Grid
                    item
                    xs={10}
                    sm={4}
                    md={4}
                    height={200}
                    sx={{
                      backgroundColor: "#191919",
                      borderRight: "1px solid #3e3f40",
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ProfileInfo userInfo={item} />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} height={10} sx={{}}></Grid>

                  <Grid
                    item
                    xs={10}
                    sm={4}
                    md={4}
                    height={380}
                    sx={{
                      backgroundColor: "#191919",
                      borderLeft: "1px solid #3e3f40",
                      borderRadius: 2,
                    }}
                  >
                    <GameInfo userInfo={item} />
                  </Grid>

                  <Grid
                    item
                    xs={10}
                    sm={4}
                    md={4}
                    sx={{
                      backgroundColor: "#191919",
                      borderRight: "1px solid #3e3f40",
                      borderRadius: 2,
                    }}
                  >
                    <CampaignForm />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} height={15} sx={{}}></Grid>

                  <Grid
                    item
                    xs={10}
                    sm={6}
                    md={8}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Connections userInfo={item}/>
                  </Grid>
                </>
              )}
              {!user && ( // Display a fallback message if user is null or undefined
                <p>User information not available.</p>
              )}
            </Grid>
          ))
        )}

        <Grid item xs={12} sm={12} md={12} height={20} sx={{}}></Grid>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconButton aria-label="fingerprint" color="secondary" onClick={handleLogout}>
            Log out
            <Fingerprint />
          </IconButton>
        </div>

        <Grid item xs={12} sm={12} md={12} height={100} sx={{}}></Grid>
      </main>

      <footer>
        <AlFooter />
      </footer>
    </div>
  );
};

export default Home;
