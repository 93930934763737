import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Stack, { Grid } from '@mui/material';
import { Button } from '@mui/material';
import Navbar from '../../components/Navbar';
import { Avatar } from '@mui/material';
import axios from 'axios';
import CampaignTime from '../../components/Campaigns/CampaignTime';
import AlFooter from '../../components/Footer/AlFooter';
import { TeamsList } from '../../components/Stars/StarsList';
import { StoresList } from '../../components/Stores/StoresList';


export const Stores = () => {

  const user = JSON.parse(localStorage.getItem('user'));
  const [data, setData] = useState(null); // Initialize as null for loading state
  const [error, setError] = useState(null); // Track errors
  const baseUrl = `https://back.airsoftlegendsapp.com/API_AL/stores.php`;

  function peticionGet() {
    axios.get(baseUrl)
      .then((response) => {
        console.log(response.data);
        console.log(user)
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError(error);
      });
  }

  useEffect(() => {
    peticionGet();
  }, []);

  return (

    <div className="profile">
      <header style={{ flexShrink: 0 }}>
        <Navbar />
      </header>

      <main style={{ flex: 1, overflow: 'auto', padding: '50px' }}>

        {error ? (
          <div>Error: {error.message}</div>
        ) : data === null ? (
          <div>Cargando datos...</div>
        ) : data.length > 0 ? (
          //data.map((item) => (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          //key={`id-${item.id}`}
          >

          <StoresList storeInfo={data}></StoresList>


          </Grid>
          //))
        ) : (
          <tr>
            <td colSpan="11">No se encontraron datos.</td>
          </tr>
        )}



      </main>

      <footer style={{ flexShrink: 0, paddingTop: '50px' }}>
        <AlFooter />
      </footer>
    </div>

    /*
    
    <React.Fragment>
            <Navbar></Navbar>
            <div>Stores</div>
            <table>
          <thead>
            <tr>
              <th>Store_Id</th>
              <th>Name</th>
              <th>Description</th>
              <th>adress</th>
              <th>Owner</th>
              <th>Municipio</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>
            {error ? (
              <tr>
                <td colSpan='11'>Error: {error.message}</td>
              </tr>
            ) : data === null ? (
              <tr>
                <td colSpan='11'>Cargando datos...</td>
              </tr>
            ) : data.length > 0 ? (
              data.map((item) => (
                <tr key={`id-${item.id}`}>
                  <td>{item.id_store}</td>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td>{item.address}</td>
                  <td>{item.owner}</td>
                  <td>{item.municipio}</td>
                  <td><Avatar alt="Remy Sharp" src={item.image} sx={{ width: 50, height: 50 }} /></td>
                  
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan='11'>No se encontraron datos.</td>
              </tr>
            )}
          </tbody>
        </table>

        <CampaignTime></CampaignTime>

        </React.Fragment>*/
  )

}

export default Stores;