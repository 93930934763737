import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const ArenasList = (props) => {
    const { arenaInfo } = props;

    return (
        <Grid container spacing={1} padding={1} sx={{ width: '80vw', height: '80vh', padding: 0 }}>
            {arenaInfo.map((arena, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Card sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '#191919', // Set background color for the Card
                        color: 'white' // Set text color for the Card
                    }}>
                        <CardActionArea>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" src={arena.image}>
                                        R
                                    </Avatar>
                                }
                                action={
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon style={{ color: '#a17d45' }} />
                                    </IconButton>
                                }
                                title={arena.nickName}
                                subheader={arena.nombre}
                                subheaderTypographyProps={{ style: { color: 'gray' } }}
                            />
                            <CardMedia
                                component="img"
                                height="140"
                                image={arena.aImage}
                                alt={arena.name}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div" sx={{ color: 'white' }}>
                                    {arena.name}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'white' }}>
                                    {arena.description}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <IconButton aria-label="add to favorites">
                                <FavoriteIcon style={{ color: '#a17d45' }} />
                            </IconButton>
                            <IconButton aria-label="share">
                                <ShareIcon style={{ color: '#a17d45' }} />
                            </IconButton>
                            <Button size="small" color="primary" style={{ color: '#a17d45' }}>
                                Share
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}
