import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Grid, IconButton, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import { red } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';


export const StoresList = (props) => {
    const { storeInfo } = props;
    const theme = useTheme();

    return (
        <Grid container spacing={1} padding={1} sx={{ width: '80vw', height: '80vh' }}>
            {storeInfo.map((store, index) => (
                <Grid item xs={12} md={4} key={index} sx={{ display: 'flex', marginBottom: '2px' }}>
                    <Card sx={{
                        height: 'calc(100% - 2px)', // Adjust height to account for the 2px margin
                        width: '100%',
                        display: 'flex',
                        backgroundColor: '#191919', // Set background color for the Card
                        color: 'white', // Set text color for the Card
                        border: '0.5px solid grey', // Add grey border
                        boxSizing: 'border-box' // Ensure border is included in the element's total width and height
                    }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>

                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" src="">
                                            R
                                        </Avatar>
                                    }
                                    title={store.name}
                                    subheader="Barcelona"
                                    subheaderTypographyProps={{ style: { color: 'gray' } }}
                                />

                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ color: 'white' }}>
                                        Score
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ color: 'grey' }}>
                                        {store.description}
                                    </Typography>
                                    <Stack spacing={1}>
                                        <Rating name="size-medium" defaultValue={store.score} />
                                    </Stack>

                                </CardContent>

                                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                    <IconButton aria-label="add to favorites">
                                        <FavoriteIcon style={{ color: '#a17d45' }} />
                                    </IconButton>
                                    <IconButton aria-label="share">
                                        <ShareIcon style={{ color: '#a17d45' }} />
                                    </IconButton>
                                    <Button size="small" color="primary" style={{ color: '#a17d45' }}>
                                        Share
                                    </Button>
                                </Box>
                            </Box>
                            <CardMedia
                                component="img"
                                sx={{ width: 150, height: '100%', objectFit: 'cover' }}
                                image={store.image}
                                alt="Live from space album cover"
                            />
                        </Box>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}
