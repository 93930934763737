import React from "react";
import { styled } from "@mui/system";
import AppBtn from "./AppBtn";
import appleImage from "./../../Images/apple.png";

export default function AlFooter() {
  return (
    <footer
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        height: "8%",
        backgroundColor: "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between", // Distribute content horizontally with even spacing
        padding: "20px", // Add padding for margin
        color:"white",
      }}
    >
      <div style={{ display: "flex" }}>
        <p style={{ marginRight: "15px" }}>&copy; 2024 Issesoft</p>{" "}
        <p style={{ marginRight: "2px" }}>About Us</p>
        <p style={{ marginRight: "15px" }}>Tems</p>
        <p style={{ marginRight: "15px" }}>Privacity</p>
        <p style={{ marginRight: "15px" }}>Cookies</p>
      </div>

      <AppBtn
        label="Get the free app"
        image={appleImage}
        onClick={() => console.log("¡Botón pulsado!")}
        sx={{ border: "2px solid gold" }}
      />
    </footer>
  );
}
