import * as React from 'react';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import axios from "axios";
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
}));

export const Connections = (props) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [data, setData] = useState(null); // Initialize as null for loading state
    const [error, setError] = useState(null); // Track errors
    const baseUrl = `https://back.airsoftlegendsapp.com/API_AL/connections.php?id_user=${props.userInfo.id_user}`;

    async function getRequest() {
        try {
            const response = await axios.get(baseUrl);
            console.log("Data fetched:", response.data);
            console.log("User:", user);
            if (Array.isArray(response.data)) {
                setData(response.data);
            } else {
                console.error("Data is not an array:", response.data);
                setError(new Error("Data format is incorrect."));
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error);
        }
    }

    useEffect(() => {
        getRequest();
    }, []);

    return (
        <Box border={0} height='100%'>
            <Stack direction="row" spacing={1}>
                {error ? (
                    <div>Error: {error.message}</div>
                ) : data === null ? (
                    <div>Cargando datos...</div>
                ) : data.length > 0 ? (
                    <>
                        {data.slice(0, 8).map((item) => (
                            <StyledBadge
                                key={item.name}
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                variant="dot"
                            >
                                <Link to="/legends" style={{ textDecoration: 'none' }}>
                                    <Avatar alt={item.name} src={item.image} sx={{ width: 80, height: 80 }} />
                                </Link>
                            </StyledBadge>
                        ))}
                        {data.length > 8 && (
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            >
                                <Link to="/legends" style={{ textDecoration: 'none' }}>
                                    <Box sx={{ position: 'relative', display: 'inline-flex', width: 80, height: 80 }}>
                                        <Avatar
                                            alt={user.name || 'User Name'}
                                            src={user.photoURL}
                                            sx={{ width: 80, height: 80 }}
                                        />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: 'white',
                                                bgcolor: 'rgba(0, 0, 0, 0.5)',
                                                borderRadius: '50%'
                                            }}
                                        >
                                            <Typography variant="h4" sx={{ color: 'white' }}>+{data.length-8}</Typography>
                                        </Box>
                                    </Box>
                                </Link>
                            </StyledBadge>
                        )}
                    </>
                ) : (
                    <tr>
                        <td colSpan="11">No se encontraron datos.</td>
                    </tr>
                )}
            </Stack>
        </Box>
    );
}
