import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Grid, IconButton, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import { red } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';


export const TeamsList = (props) => {
    const { teamInfo } = props;
    const theme = useTheme();
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    return (
        <Grid container spacing={1} padding={1} sx={{ width: '80vw', height: '80vh'}}>
            {teamInfo.map((team, index) => (
                <Grid item xs={12} md={6} key={index} sx={{ display: 'flex', marginBottom: '2px' }}>
                    <Card sx={{
                        height: '100%', // Adjust height to account for the 2px margin
                        width: '100%',
                        display: 'flex',
                        backgroundColor: '#191919', // Set background color for the Card
                        color: 'white', // Set text color for the Card
                        border: '0.5px solid grey', // Add grey border
                        boxSizing: 'border-box', // Ensure border is included in the element's total width and height
                        borderRadius: 2,
                    }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center' }}>

                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <CardHeader
                                        avatar={
                                            <Avatar sx={{ bgcolor: red[500], width: 30, height: 30, border: '2px solid #a17d45' }} aria-label="recipe" src="https://lh3.googleusercontent.com/a-/AFdZucqNxaV90Z5PDOmOSaaQWuft8_CvnH3PB57PoqYD=s96-c">
                                                R
                                            </Avatar>
                                        }
                                        title={team.name}
                                    />
                                    <Box sx={{ padding: 0.5, borderRadius: '50%', border: '3px solid #a17d45', display: 'inline-block' }}>{/* Added Box for padding */}
                                        <Avatar
                                            sx={{
                                                bgcolor: red[500],
                                                width: 100,
                                                height: 100,
                                            }}
                                            aria-label="recipe"
                                            src=""
                                        >
                                            R
                                        </Avatar>
                                    </Box>

                                </Box>


                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>

                                <CardHeader

                                    title={team.name}
                                    subheader="Barcelona"
                                    subheaderTypographyProps={{ style: { color: 'gray' } }}
                                />

                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ color: 'grey' }}>
                                        {team.description}
                                    </Typography>
                                </CardContent>

                                <CardContent sx={{ flex: '1 0 auto' }}>

                                    <Stack
                                        direction="row"
                                        divider={<Divider orientation="vertical" color='darkgrey' flexItem />}
                                        spacing={1}
                                        justifyContent="center" alignItems="center"
                                        marginTop={1}
                                        height={30}

                                    >
                                        <Item sx={{ backgroundColor: '#191919', color: "white" }}>100
                                            <Typography variant="caption" component="h2" sx={{ color: 'white' }}>
                                                Connections
                                            </Typography>
                                        </Item>

                                        <Item sx={{ backgroundColor: '#191919', color: "white" }}>50
                                            <Typography variant="caption" component="h2">
                                                Played
                                            </Typography>
                                        </Item>

                                        <Item sx={{ backgroundColor: '#191919', color: "white" }}>7
                                            <Typography variant="caption" component="h2">
                                                Hosted
                                            </Typography>
                                        </Item>

                                        <Item sx={{ backgroundColor: '#191919', color: "white" }}>5
                                            <Typography variant="caption" component="h2">
                                                Awards
                                            </Typography>
                                        </Item>
                                    </Stack>

                                </CardContent>

                                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom:'10px'}}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{
                                            color: '#a17d45',
                                            border: '1px solid #a17d45',
                                            margin: '1px',
                                            width: '80%' // Ajusta el ancho al 80% del contenedor
                                        }}
                                    >
                                        Share
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}
