import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material";
import { Button } from "@mui/material";
import Navbar from "../../components/Navbar";
import { Avatar } from "@mui/material";
import axios from "axios";
import AlFooter from "../../components/Footer/AlFooter";
import Grid from "@mui/material/Grid";
import SelectTextFields from "../../components/TextField/Select";
import { AvatarInfo } from "../../components/User/AvatarInfo";
import { ProfileInfo } from "../../components/User/ProfileInfo";
import GameInfo from "../../components/User/GameInfo";
import CampaignForm from "../../components/Campaigns/CampaignForm";
import Connections from "../../components/User/Connections";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { Navigate } from "react-router-dom";
import { TeamsList } from "../../components/Teams/TeamsList";

export const Teams = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState(null); // Initialize as null for loading state
  const [error, setError] = useState(null); // Track errors
  const baseUrl = `https://back.airsoftlegendsapp.com/API_AL/teams.php`;

  function peticionGet() {
    axios
      .get(baseUrl)
      .then((response) => {
        console.log(response.data);
        console.log(user);
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
      });
  }


  useEffect(() => {
    peticionGet();
  }, []);


  return (

    <div className="profile">
      <header style={{ flexShrink: 0 }}>
        <Navbar />
      </header>

      <main style={{ flex: 1, overflow: 'auto', padding: '50px'}}>

        {error ? (
          <div>Error: {error.message}</div>
        ) : data === null ? (
          <div>Cargando datos...</div>
        ) : data.length > 0 ? (
          //data.map((item) => (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          //key={`id-${item.id}`}
          >
            <TeamsList teamInfo={data}/>

          </Grid>
          //))
        ) : (
          <tr>
            <td colSpan="11">No se encontraron datos.</td>
          </tr>
        )}



      </main>

      <footer style={{ flexShrink: 0, paddingTop: '50px' }}>
        <AlFooter />
      </footer>
    </div>

    /* <div className="profile">
 
       <header>
 
         <Navbar></Navbar>
       </header>
 
 
       <main>
 
         <div>isain</div>
 
         <Grid
           container
           xs={12}
           sm={12}
           md={12}
           sx={{
             display: "flex",
             alignItems: "center", // Center vertically
             justifyContent: "center",
           }}
         >
           <Grid item xs={4} sm={4} md={4} sx={{}}>
             <SelectTextFields></SelectTextFields>
           </Grid>
 
           <Grid item xs={4} sm={4} md={4} sx={{}}>
             <SelectTextFields></SelectTextFields>
           </Grid>
           <Grid item xs={4} sm={4} md={4} sx={{}}>
             <SelectTextFields></SelectTextFields>
           </Grid>
         </Grid>
         {error ? (
           <div>Error: {error.message}</div>
         ) : data === null ? (
           <div>Cargando datos...</div>
         ) : data.length > 0 ? (
           data.map((item) => (
             <Grid
               container
               xs={12}
               sm={12}
               md={12}
               style={{
                 display: "flex", // Add display: flex for horizontal centering
                 justifyContent: "center", // Center the content horizontally
                 alignItems: "center", // Center the content vertically (optional)
                 position: "relative",
               }}
               key={`id-${item.id}`}
             >
               <Grid
                 container
                 xs={8}
                 sm={8}
                 md={8}
                 style={{
                   backgroundColor: "#191919",
                   height: "100%",
                   overflowX: "auto", // Add overflowY to allow scrolling within the container (optional)
                   position: "relative", // Make the container position relative for fixed positioning (optional)
                   border: "2px solid #3D3E3F",
                   display: "flex", // Add display: flex for horizontal centering
                   justifyContent: "center", // Center the content horizontally
                   alignItems: "center", // Center the content vertically (optional)
                 }}
               >
                 <Grid
                   item
                   xs={10}
                   sm={4}
                   md={4}
                   height={200}
                   sx={{
                     backgroundColor: "#191919",
                     borderLeft: "1px solid #3e3f40", // Concise and consistent border application
                     borderRadius: 2,
                     marginTop: 5,
                   }}
                 >
                   <AvatarInfo userInfo={item}></AvatarInfo>
                 </Grid>
 
                 <Grid
                   item
                   xs={10}
                   sm={4}
                   md={4}
                   height={200}
                   sx={{
                     backgroundColor: "#191919",
                     borderRight: "1px solid #3e3f40", // Concise and consistent border application
                     borderRadius: 2,
                     display: "flex",
                     alignItems: "center", // Center vertically
                     justifyContent: "center",
                     marginTop: 5,
                   }}
                 >
                   <ProfileInfo userInfo={item}></ProfileInfo>
                 </Grid>
 
                 <Grid item xs={12} sm={12} md={12} height={50} sx={{}}></Grid>
               </Grid>
               <Grid item xs={12} sm={12} md={12} height={10} sx={{}}></Grid>
             </Grid>
           ))
         ) : (
           <tr>
             <td colSpan="11">No se encontraron datos.</td>
           </tr>
         )}
 
         <div>Teams</div>
 
         <Grid
           container
           width="100%"
           justifyContent="center"
           sx={
             {
               // Darkgray color code
             }
           }
         >
           <Grid item xs={12} sm={12} md={12} height={50} sx={{}}></Grid>
           {user && ( // Check if user exists before rendering its info
             <></>
           )}
           {!user && ( // Display a fallback message if user is null or undefined
             <p>User information not available.</p>
           )}
         </Grid>
       </main>
 
 
       <footer>
 
         <AlFooter></AlFooter>
       </footer>
     </div>*/

    /*<React.Fragment>
      <Navbar></Navbar>
      
        <div>Teams</div>
      <table>
        <thead>
          <tr>
            <th>Team_Id</th>
            <th>Name</th>
            <th>Description</th>
            <th>Capacity</th>
            <th>Created date</th>
            <th>Owner</th>
            <th>Image</th>
          </tr>
        </thead>
        <tbody>
          {error ? (
            <tr>
              <td colSpan="11">Error: {error.message}</td>
            </tr>
          ) : data === null ? (
            <tr>
              <td colSpan="11">Cargando datos...</td>
            </tr>
          ) : data.length > 0 ? (
            data.map((item) => (
              <tr key={`id-${item.id}`}>
                <td>{item.id_team}</td>
                <td>{item.name}</td>
                <td>{item.description}</td>
                <td>{item.address}</td>
                <td>{item.owner}</td>
                <td>{item.municipio}</td>
                <td>
                  <Avatar
                    alt="Remy Sharp"
                    src={item.image}
                    sx={{ width: 50, height: 50 }}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="11">No se encontraron datos.</td>
            </tr>
          )}
        </tbody>
      </table>
    </React.Fragment>*/
  );
};

export default Teams;
