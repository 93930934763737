import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import Navbar from '../../components/Navbar';
import { Avatar } from '@mui/material';
import axios from 'axios';
import SelectTextFields from '../../components/TextField/Select';
import AlFooter from '../../components/Footer/AlFooter';
import { StarsList } from '../../components/Stars/StarsList';

export const Stars = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [data, setData] = useState(null); // Initialize as null for loading state
  const [error, setError] = useState(null); // Track errors
  const baseUrl = `https://back.airsoftlegendsapp.com/API_AL/stars.php`;

  function peticionGet() {
    axios.get(baseUrl)
      .then((response) => {
        console.log(response.data);
        console.log(user)
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError(error);
      });
  }

  useEffect(() => {
    peticionGet();
  }, []);

  return (
    <div className="profile">
      <header style={{ flexShrink: 0 }}>
        <Navbar />
      </header>

      <main style={{ flex: 1, overflow: 'auto', padding: '50px' }}>

        {error ? (
          <div>Error: {error.message}</div>
        ) : data === null ? (
          <div>Cargando datos...</div>
        ) : data.length > 0 ? (
          //data.map((item) => (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          //key={`id-${item.id}`}
          >

            <StarsList starInfo={data} />


          </Grid>
          //))
        ) : (
          <tr>
            <td colSpan="11">No se encontraron datos.</td>
          </tr>
        )}



      </main>

      <footer style={{ flexShrink: 0, paddingTop: '50px' }}>
        <AlFooter />
      </footer>
    </div>

  );
}

export default Stars;
