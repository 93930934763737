//rafc
import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material";
import { Button } from "@mui/material";
import Navbar from "../../components/Navbar";
import { Navigate, useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import axios from "axios";
import AlFooter from "../../components/Footer/AlFooter";
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CampaignForm from "../../components/Campaigns/CampaignForm";
import { ArenasList } from "../../components/Arenas/ArenasList";

export const Arenas = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState(null); // Initialize as null for loading state
  const [error, setError] = useState(null); // Track errors
  const baseUrl = `https://back.airsoftlegendsapp.com/API_AL/arenas.php`;

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  function peticionGet() {
    axios
      .get(baseUrl)
      .then((response) => {
        console.log(response.data);
        console.log(user);
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
      });
  }

  function createArena() {
    navigate("/createArena");
  }

  useEffect(() => {
    peticionGet();
  }, []);

  return (

    <div className="profile">
      <header style={{ flexShrink: 0 }}>
        <Navbar />
      </header>

      <main style={{ flex: 1, overflow: 'auto', padding: '50px' }}>

        {error ? (
          <div>Error: {error.message}</div>
        ) : data === null ? (
          <div>Cargando datos...</div>
        ) : data.length > 0 ? (
          //data.map((item) => (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              //key={`id-${item.id}`}
            >
              
                <ArenasList arenaInfo={data} />
              

            </Grid>
          //))
        ) : (
          <tr>
            <td colSpan="11">No se encontraron datos.</td>
          </tr>
        )}



      </main>

      <footer style={{ flexShrink: 0, paddingTop: '50px' }}>
        <AlFooter />
      </footer>
    </div>



    /*<React.Fragment>
      <Navbar></Navbar>
      <div
        style={{
          backgroundColor: "white",
        }}
      >
        Arenas
        <Grid container sx={{
          display: 'flex',
          alignItems: 'center', // Center vertically
          justifyContent: 'center'
        }}>
          <Grid item xs={8}>
            <Item>xs=8</Item>

            <table>
          <thead>
            <tr>
              <th>Arena_Id</th>
              <th>Nombre</th>
              <th>Puntaje</th>
              <th>Direccion</th>
              <th>Telefono</th>
              <th>Tipo de Arena</th>
              <th>Pagina Web</th>
              <th>Descripción</th>
              <th>Locación</th>
              <th>Imagen</th>
            </tr>
          </thead>
          <tbody>
            {error ? (
              <tr>
                <td colSpan="11">Error: {error.message}</td>
              </tr>
            ) : data === null ? (
              <tr>
                <td colSpan="11">Cargando datos...</td>
              </tr>
            ) : data.length > 0 ? (
              data.map((item) => (
                <tr key={`id-${item.id}`}>
                  <td>{item.id_arena}</td>
                  <td>{item.name}</td>
                  <td>{item.score}</td>
                  <td>{item.address}</td>
                  <td>{item.phone}</td>
                  <td>{item.arena_Type}</td>
                  <td>{item.website}</td>
                  <td>{item.description}</td>
                  <td>{item.location}</td>
                  <td>
                    <Avatar
                      alt="Remy Sharp"
                      src={item.image}
                      sx={{ width: 50, height: 50 }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="11">No se encontraron datos.</td>
              </tr>
            )}
          </tbody>

          <button onClick={createArena}>CREATE ARENA</button>
        </table>
          
          </Grid>
        </Grid>
        
      </div>

      <footer>
        <AlFooter></AlFooter>
      </footer>
    </React.Fragment>*/
  );
};

export default Arenas;
