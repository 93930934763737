import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material";
import { Button } from "@mui/material";
import Navbar from "../../components/Navbar";
import { Avatar } from "@mui/material";
import axios from "axios";
import "../../Styles/Page.css";
import Grid from "@mui/material/Grid";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AlFooter from "../../components/Footer/AlFooter";
import { styled } from "@mui/material/styles";
import CampaignForm from "../../components/Campaigns/CampaignForm";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Gradient } from "@mui/icons-material";
import SelectTextFields from "../../components/TextField/Select";
import { CampaignsDetail } from "../../components/Campaigns/CampaignsDetail";
import CampaignTime from "../../components/Campaigns/CampaignTime";

export const Campaigns = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState(null); // Initialize as null for loading state
  const [error, setError] = useState(null); // Track errors
  const baseUrl = `https://back.airsoftlegendsapp.com/API_AL/campaigns.php`;
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const itemData = [
    {
      img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
      title: "Breakfast",
    },
    {
      img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
      title: "Burger",
    },
    {
      img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
      title: "Camera",
    },
    {
      img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
      title: "Coffee",
    },
    {
      img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
      title: "Hats",
    },
    {
      img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
      title: "Honey",
    },
    {
      img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
      title: "Basketball",
    },
    {
      img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
      title: "Fern",
    },
    {
      img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
      title: "Mushrooms",
    },
    {
      img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
      title: "Tomato basil",
    },
    {
      img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
      title: "Sea star",
    },
    {
      img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
      title: "Bike",
    },
  ];

  function peticionGet() {
    axios
      .get(baseUrl)
      .then((response) => {
        console.log(response.data);
        console.log(user);
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
      });
  }

  useEffect(() => {
    peticionGet();
  }, []);

  return (
    <React.Fragment>
      <Navbar></Navbar>
      <div
        style={{
          backgroundColor: "black",
          height: "1000vh",
        }}
      >Campaign
        
        <Grid container xs={12}sm={12}md={12} sx={{
            display: "flex",
            alignItems: "center", // Center vertically
            justifyContent: "center",
          }}>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            sx={{
              
            }}
          >
            <SelectTextFields></SelectTextFields>
          </Grid>

          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            sx={{
              
            }}
          >
            <SelectTextFields></SelectTextFields>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            sx={{
              
            }}
          >
            <SelectTextFields></SelectTextFields>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            sx={{
              
            }}
          >
            <SelectTextFields></SelectTextFields>
          </Grid>
        </Grid>
        
        <div> Campaigns</div>


        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center", // Center vertically
            justifyContent: "center",
          }}
        >
          <Grid item xs={8} sm={8} md={8} sx={{}}>
            {error ? (
              <tr>
                <td colSpan="11">Error: {error.message}</td>
              </tr>
            ) : data === null ? (
              <tr>
                <td colSpan="11">Cargando datos...</td>
              </tr>
            ) : data.length > 0 ? (
              <ImageList
                sx={{ width: "100%", height: "100%" }}
                cols={2}
                rowHeight={164}
              >
                {data.map((item) => (
                  <ImageListItem key={item}>
                    <CampaignsDetail name={item.name} description={item.description} date={item.date} CampaignFname={item.CampaignFname} MunicipioName={item.MunicipioName} userName={item.userName} userImage={item.userImage}></CampaignsDetail>
                  </ImageListItem>
                ))}
              </ImageList>
            ) : (
              <tr>
                <td colSpan="11">No se encontraron datos.</td>
              </tr>
            )}
          </Grid>
        </Grid>
        <footer>
          <AlFooter></AlFooter>
        </footer>
      </div>
    </React.Fragment>

    /*
    <React.Fragment>
        <Navbar></Navbar>
        <div>Campaigns</div>
        <table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Date</th>
              <th>Price</th>
              <th>Description</th>
              <th>PlayersNumber</th>
              <th>Created_Date</th>
              <th>Updated_Date</th>
              <th>CampaignType</th>
              <th>CampaignForm</th>
              <th>Owner</th>
              <th>image</th>

            </tr>
          </thead>
          <tbody>
            {error ? (
              <tr>
                <td colSpan='11'>Error: {error.message}</td>
              </tr>
            ) : data === null ? (
              <tr>
                <td colSpan='11'>Cargando datos...</td>
              </tr>
            ) : data.length > 0 ? (
              data.map((item) => (
                <tr key={`id-${item.id}`}>
                  <td>{item.id_campaign}</td>
                  <td>{item.name}</td>
                  <td>{item.date}</td>
                  <td>{item.price}</td>
                  <td>{item.description}</td>
                  <td>{item.playersNumber}</td>
                  <td>{item.created_date}</td>
                  <td>{item.updated_date}</td>
                  <td>{item.id_campaignType}</td>
                  <td>{item.id_campaignForm}</td>
                  <td>{item.id_owner}</td>
                  <td><Avatar alt="Remy Sharp" src={item.image} sx={{ width: 50, height: 50 }} /></td>
                  
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan='11'>No se encontraron datos.</td>
              </tr>
            )}
          </tbody>
        </table>

        
    </React.Fragment>*/
    /*
    <ImageList
              sx={{ width: "100%", height: "100%" }}
              cols={2}
              rowHeight={164}
            >
              {data.map((item) => (
                <ImageListItem key={item}>
                  <CampaignForm></CampaignForm>
                </ImageListItem>
              ))}
            </ImageList>
     */
  );
};

export default Campaigns;
