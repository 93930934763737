import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

export const ProfileInfo = (props) => {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));

    return (
        <Box border={0} height='90%' width='90%'>
                <Typography variant="h6" component="h4" sx={{ color: 'white' }} >
                  {props.userInfo.name}
                </Typography>
                <Typography variant="caption" component="h4" sx={{ color: 'white' }}>
                  {props.userInfo.nickName}
                </Typography>
                <Typography variant="caption" align='left' sx={{ color: 'white' }}>
                  {props.userInfo.description}
                </Typography>
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" color='darkgrey' flexItem />}
                    spacing={1}
                    justifyContent="center" alignItems="center"
                    marginTop={1}
                    height={30}
                    
                >
                    <Item sx={{ backgroundColor: '#191919', color:"white"}}>{props.userInfo.connections}
                        <Typography variant="caption" component="h2" sx={{ color: 'white'}}>
                            Connections
                        </Typography>
                    </Item>

                    <Item sx={{ backgroundColor: '#191919', color:"white"}}>{props.userInfo.played}
                        <Typography variant="caption" component="h2">
                            Played
                        </Typography>
                    </Item>

                    <Item sx={{ backgroundColor: '#191919', color:"white"}}>{props.userInfo.hosted}
                        <Typography variant="caption" component="h2">
                            Hosted
                        </Typography>
                    </Item>

                    <Item sx={{ backgroundColor: '#191919', color:"white"}}>{props.userInfo.awards}
                        <Typography variant="caption" component="h2">
                            Awards
                        </Typography>
                    </Item>
                </Stack>

                <Button variant="outlined" size="large" endIcon={<SendIcon />} sx={{
                width: '100%',
                height: '20%',
                marginTop: 1,
                color: '#a17d45', // Set text color to #a17d45
                borderColor: '#a17d45', // Set border color to #a17d45
                }}>
                Create Campaign
                </Button>
                            
              </Box>
    );
}