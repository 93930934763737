
import { signOut } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from '@mui/material/Button';
import { Avatar } from '@mui/material';
import "../../Styles/Form.css";

export const CreateArena = () => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [type, setType] = useState('');
  const [webPage, setWebPage] = useState('');
  const [phone, setPhone] = useState('');
  const [rentMaterial, setRentMaterial] = useState(false);
  const [id_user, setIdUser] = useState(1); // Assuming a default user ID
  const [id_municipio, setIdMunicipio] = useState(0); // Replace with appropriate value
  const formData = {
    name,
    address,
    description,
    image,
    type,
    webPage,
    phone,
    rentMaterial,
    id_user,
    id_municipio,
  };

  const handleSubmit = (event) => {
    event.preventDefault();


    // Handle form submission here (e.g., send data to backend using fetch or Axios)
    console.log('Form data:', formData);
    insertArena();
    // Reset the form after submission (optional)
    setName('');
    setAddress('');
    setDescription('');
    setImage('');
    setType('');
    setWebPage('');
    setPhone('');
    setIdMunicipio('');
    setRentMaterial(false);
    
  };

  const insertArena = async () => {
    try {

      await axios.post('https://localhost/API_AL/registerArena.php', {
        name: formData.name,
        address: formData.address,
        description: formData.description,
        image: formData.image,
        type: formData.type,
        webPage: formData.webPage,
        phone: formData.phone,
        rentMaterial: formData.rentMaterial,
        id_user: formData.id_user,
        id_municipio: formData.id_municipio,

      });

      console.log('se creo!');

    } catch (error) {
      console.error('Error:', error);
      // Handle errors appropriately, e.g., display error messages to the user
    }
  };

  return (
    <div className="form-container"> {/* Added a container for styling */}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="address">Address:</label>
          <input
            type="text"
            id="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="image">Image URL:</label>
          <input
            type="url"
            id="image"
            value={image}
            onChange={(e) => setImage(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="type">Type:</label>
          <input
            type="text"
            id="type"
            value={type}
            onChange={(e) => setType(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="webPage">Web Page:</label>
          <input
            type="url"
            id="webPage"
            value={webPage}
            onChange={(e) => setWebPage(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="phone">Phone Number:</label>
          <input
            type="tel" // Improved input type for phone numbers
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="id_municipio">Municipio:</label>
          <input
            type="text" // Improved input type for phone numbers
            id="id_municipio"
            value={id_municipio}
            onChange={(e) => setIdMunicipio(e.target.value)}
          />
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              id="rentMaterial"
              checked={rentMaterial}
              onChange={(e) => setRentMaterial(e.target.checked)}
            />
            Rent Material Available
          </label>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default CreateArena;




