import { Avatar, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";

export const AvatarInfo = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <Box
      border={0}
      height={200}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item>
            <Typography variant="h6" component="h2" sx={{ color: "white" }}>
              {props.userInfo.sDescription}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              alt={props.userInfo.name || "User Name"}
              src={props.userInfo.image}
              sx={{ width: 150, height: 150 }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
