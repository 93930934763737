import React, { createContext, useState, useEffect } from 'react';
import GoogleButton from 'react-google-button';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase';
import axios from 'axios'; // Corrected import


import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import "../Styles/Login.css";
import { pink } from '@mui/material/colors';



const AuthContext = createContext();

export const Login = () => {
  const navigate = useNavigate();
  const defaultTheme = createTheme();
  const [user, setUser] = useState(null); // Initialize as null

  

  const googleSignIn = async () => {
    try {
      console.log("pulsando!!!");
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);

      localStorage.setItem('token', result.user.accessToken);
      localStorage.setItem('user', JSON.stringify(result.user));

      await axios.post('https://back.airsoftlegendsapp.com/API_AL/register.php', {
        name: result.user.displayName,
        email: result.user.email,
        image: result.user.photoURL,
      });

      navigate("/");
    } catch (error) {
      console.error('Error:', error);
      // Handle errors appropriately, e.g., display error messages to the user
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        //navigate('/'); // Redirect to Home if already logged in
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(https://airsoftlegendsapp.com/login1.jpeg)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className='centrarContainer'>
          <img alt="Remy Sharp" src="https://airsoftlegendsapp.com/logo+letras.png" className='logoImage'></img>
        </div>
      </Grid>
      <Grid item xs={12} sm={8} md={5} 
         
        component={Paper} 
        elevation={6} 
        square 
        style={{ backgroundColor: 'black' }}
        >
          


          
        <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              
            }}
          >
          
          <img alt="Remy Sharp" src="https://airsoftlegendsapp.com/logo+letras.png" className='logoImage'>
          </img>
          <Typography component="h1" variant="h5" style={{ color: 'white' }}>
            Welcome!!
          </Typography>
          
          <Typography component="h1" variant="h5" style={{ color: 'white' }}>
            Sign in
          </Typography>
          <Box component="form" noValidate  sx={{ mt: 1 }}>
            
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              placeholder='email'
              autoComplete="email"
              variant="standard" 
              sx={{
                "& .MuiInputLabel-root": { color: 'darkgray' }, // Target label
                "& .MuiInputBase-input": { color: 'darkgray' }, // Target input field text
              }}
              autoFocus
            />
            
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              variant="standard" 
              sx={{
                "& .MuiInputLabel-root": { color: 'darkgray' }, // Target label
                "& .MuiInputBase-input": { color: 'darkgray' }, // Target input field text
              }}
              autoComplete="current-password"
            />

            
            <FormControlLabel
              control={<Checkbox value="remember" sx={{
                color: pink[800],
                '&.Mui-checked': {
                  color: pink[600],
                },
              }} />}
              style={{ color: 'darkgray' }}
              label="Remember me"
            />
            
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              
              sx={{ mt: 3, mb: 2, borderColor: 'orange' }}
              style={{ color: 'orange' }}
            >
              Sign In
            </Button>
            
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              padding={5}
            >
              <GoogleButton onClick={googleSignIn} />
            </Box>
            <Grid container sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              
              <Grid item>
                <Link href="#" variant="body2" > 
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            
          </Box>
        </Box>
      </Grid>
    </Grid>
  </ThemeProvider>

      

      
 
    
  );
};

export default Login;
