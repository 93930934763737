import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Masonry from '@mui/lab/Masonry';
import CampaignForm from "../../components/Campaigns/CampaignForm";
import { ProfileInfo } from "../../components/User/ProfileInfo";
import { AvatarInfo } from "../../components/User/AvatarInfo";
import { GameInfo } from "../../components/User/GameInfo";
import Connections from "../../components/User/Connections";
import { Box } from "@mui/material";
import "../../Styles/Page.css";
import AlFooter from "../../components/Footer/AlFooter";
import Navbar from "../../components/Navbar";
import axios from "axios";
import { TeamsList } from "../../components/Teams/TeamsList";
import { NewsList } from "../../components/News/NewsList";
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import { red } from '@mui/material/colors';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';


export const News = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState(null); // Initialize as null for loading state
  const [error, setError] = useState(null); // Track errors
  const baseUrl = `https://back.airsoftlegendsapp.com/API_AL/teams.php`;

  const Label = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  }));

  const itemData = [
    {
      img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
      title: 'Fern',
    },
    {
      img: 'https://images.unsplash.com/photo-1627308595229-7830a5c91f9f',
      title: 'Snacks',
    },
    {
      img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
      title: 'Mushrooms',
    },
    {
      img: 'https://images.unsplash.com/photo-1529655683826-aba9b3e77383',
      title: 'Tower',
    },
    {
      img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
      title: 'Sea star',
    },
    {
      img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
      title: 'Honey',
    },
    {
      img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
      title: 'Basketball',
    },
    {
      img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
      title: 'Breakfast',
    },
    {
      img: 'https://images.unsplash.com/photo-1627328715728-7bcc1b5db87d',
      title: 'Tree',
    },
    {
      img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
      title: 'Burger',
    },
    {
      img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
      title: 'Camera',
    },
    {
      img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
      title: 'Coffee',
    },
    {
      img: 'https://images.unsplash.com/photo-1627000086207-76eabf23aa2e',
      title: 'Camping Car',
    },
    {
      img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
      title: 'Hats',
    },
    {
      img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
      title: 'Tomato basil',
    },
    {
      img: 'https://images.unsplash.com/photo-1627328561499-a3584d4ee4f7',
      title: 'Mountain',
    },
    {
      img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
      title: 'Bike',
    },
  ];

  function peticionGet() {
    axios
      .get(baseUrl)
      .then((response) => {
        console.log(response.data);
        console.log(user);
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
      });
  }


  useEffect(() => {
    peticionGet();
  }, []);


  return (
    <div className="profile">
      <header style={{ flexShrink: 0 }}>
        <Navbar />
      </header>

      <main style={{ flex: 1, overflow: 'auto', padding: '50px' }}>

        {error ? (
          <div>Error: {error.message}</div>
        ) : data === null ? (
          <div>Cargando datos...</div>
        ) : data.length > 0 ? (
          //data.map((item) => (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          //key={`id-${item.id}`}
          >
            <Box sx={{ width: '80vw', justifyContent: 'center' }}>
              <Masonry columns={3} spacing={2}>
                {itemData.map((item, index) => (
                  <div key={index}>
                    <NewsList />
                  </div>
                ))}
              </Masonry>
            </Box>
          </Grid>

          //))
        ) : (
          <tr>
            <td colSpan="11">No se encontraron datos.</td>
          </tr>
        )}



      </main>

      <footer style={{ flexShrink: 0, paddingTop: '50px' }}>
        <AlFooter />
      </footer>
    </div>


  );
};

export default News;
